import { css } from '@emotion/core'
import styled from '@emotion/styled'

const Text = styled.p`
  ${({
    theme,
    color = 'jet',
    size = 18,
    lineHeight = 22,
    marginBottom = 0,
    marginBottomMobile = 0,
    weight,
    align,
    italic,
    transform,
    mobileSize,
    mobileHeight,
    mobileAlign,
    mobileWeight,
  }) => css`
    color: ${theme.colors[color]};
    font-size: ${size}px;
    line-height: ${lineHeight}px;
    ${weight && `font-weight: ${weight}`};
    ${align && `text-align: ${align}`};
    ${transform && `text-transform: ${transform}`};
    ${italic && `font-style: italic`};

    ${(mobileSize ||
      mobileHeight ||
      mobileAlign ||
      mobileWeight ||
      marginBottomMobile) &&
      `
      ${theme.queries.small} {
        ${mobileSize && `font-size: ${mobileSize}px`};
        ${mobileHeight && `line-height: ${mobileHeight}px`};
        ${mobileAlign && `text-align: ${mobileAlign}`};
        ${mobileWeight && `font-weight: ${mobileWeight}`};
        ${marginBottomMobile && `margin-bottom: ${marginBottomMobile}px`};
      }
    `}

    ${theme.queries.medium} {
      ${marginBottom && `margin-bottom: ${marginBottom}px`}
    }
  `};
`

export default Text
