/** @jsx jsx */
import { jsx, css, keyframes } from '@emotion/core'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

export const Divisor = styled.hr`
  ${({ theme }) => css`
    ${theme.queries.small} {
      border-top: 1px solid #c1c1c1;
      height: 0px;
      margin: 20px auto;
      width: 90%;
    }
    ${theme.queries.medium} {
      border-left: 1px solid #c1c1c1;
      height: 25px;
      margin: 0 20px;
      width: 0px;
    }
  `}
`

export const Container = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.lightgray};
    border-radius: 5px;
    height: 10px;
    width: 100%;
  `}
`

export const progressBarWidth = keyframes`
  from {
    width: 0%;
  }

  to {
    width: 100%
  }
`

const Progress = styled.div`
  ${({ percentage }) => css`
    animation: ${progressBarWidth} 8s ease-out;
    background: #21a44b;
    border-radius: 5px;
    height: 10px;
    max-width: ${percentage}%;
  `}
`

export const ProgressBar = ({ percentage }) => (
  <Container>
    <Progress percentage={percentage} />
  </Container>
)

ProgressBar.propTypes = {
  percentage: PropTypes.number.isRequired,
}
