/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import Img from 'gatsby-image'
import CountUp from 'react-countup'
import { toast } from 'react-toastify'

import { useIntl } from 'gatsby-plugin-intl'
import { fetchInstitutionLastDonation } from '../../services/institutions'

import Layout from '../../components/Layout/landing'
import FlexContainer from '../../components/FlexContainer'
import Spacing from '../../components/Spacing'
import Text from '../../components/Text'
import Button from '../../components/Button'

import { formatMoney, currencies } from '../../utils/money'
import { isEquivalent } from '../../utils/objectCompare'

import { Divisor, ProgressBar } from './styles'

const Live = ({ pageContext }) => {
  const intl = useIntl()
  const { institutionId, campaignId } = pageContext

  const [donation, setDonation] = useState({})
  const [hasNewDonation, setNewDonation] = useState(true)

  const [percentage, setPercentage] = useState(0)

  const fetchLastDonation = async () => {
    try {
      const response = await fetchInstitutionLastDonation(
        institutionId,
        campaignId
      )

      const newDonation = response.data

      if (isEquivalent(newDonation, donation)) {
        setNewDonation(false)
        return
      }

      setDonation(newDonation)
      setNewDonation(true)

      if (newDonation.goalValue > 0) {
        const percentage =
          (newDonation.totalValue / newDonation.goalValue) * 100

        setPercentage(percentage)
      }
    } catch (error) {
      toast.info(intl.formatMessage({ id: 'toast_messages.error.donation' }))
    }
  }

  useEffect(() => {
    fetchLastDonation()
  }, [])

  useEffect(() => {
    const interval = setInterval(fetchLastDonation, 10000)
    return () => clearInterval(interval)
  })

  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo-header.png" }) {
        childImageSharp {
          fluid(maxWidth: 206) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const handleDonate = () => {
    if (campaignId) {
      navigate(`/pt/campanhas/doar/${campaignId}/`)
    } else {
      navigate(`/pt/instituicoes/doar/${institutionId}/`)
    }
  }

  return (
    <Layout>
      <Spacing top="40" topMobile="30">
        <FlexContainer
          css={theme => css`
            background: #ffffff;
            border-radius: 10px;
            box-shadow: 0px 2px 10px #7c7c7c80;
            margin: auto;
            padding: 20px 10px;
            width: 90%;

            ${theme.queries.small} {
              flex-direction: column;
              height: 430px;
            }

            ${theme.queries.medium} {
              align-items: center;
              justify-content: space-between;
              padding: 30px;
            }
          `}
        >
          <Img
            loading="eager"
            fadeIn={false}
            alt="Denario Logo"
            fluid={data.logo.childImageSharp.fluid}
            css={theme => css`
              ${theme.queries.small} {
                height: auto;
                margin: 0 auto 30px;
                width: 80%;
              }
              ${theme.queries.medium} {
                height: auto;
                max-height: 70px;
                max-width: 206px;
                width: 20%;
              }
            `}
          />

          <FlexContainer
            css={theme => css`
              ${theme.queries.small} {
                flex-direction: column;
                justify-content: space-between;
                flex: 1;
              }
              ${theme.queries.medium} {
                align-items: center;
                justify-content: space-between;
                flex: 1;
                margin-left: 15px;
              }
            `}
          >
            <div
              css={css`
                flex: 1;
              `}
            >
              {donation.donator && hasNewDonation ? (
                <Text lineHeight="28" align="center">
                  Obrigado, <strong>{donation.donator || ''}</strong> pela
                  doação de{' '}
                  <strong>
                    {formatMoney(currencies.BRL)(donation.donatedValue)}
                  </strong>
                  !
                </Text>
              ) : (
                <Text align="center">Colabore com a instituição!</Text>
              )}
            </div>

            <Divisor />

            <FlexContainer
              css={theme => css`
                flex-direction: column;

                ${theme.queries.small} {
                  text-align: center;
                }

                ${theme.queries.medium} {
                  flex: 1;
                }
              `}
            >
              <Text>{donation.institutionName}</Text>

              {donation.campaignName && (
                <Text size="16" color="gray">
                  {donation.campaignName}
                </Text>
              )}
            </FlexContainer>

            <FlexContainer
              css={theme => css`
                align-items: flex-end;
                height: 100%;
                flex-direction: column;
                justify-content: space-between;

                ${theme.queries.small} {
                  align-items: center;
                  margin-top: 30px;
                  width: 100%;
                }

                ${theme.queries.medium} {
                  margin-left: 15px;
                  margin-right: 15px;
                  width: 120px;
                }
              `}
            >
              <Text
                color="olivergreen"
                marginBottom="8"
                marginBottomMobile="14"
                mobileSize="28"
                size="26"
              >
                <CountUp
                  start={0}
                  end={donation.totalValue || 0}
                  duration={2}
                  prefix="R$ "
                  decimals={2}
                  decimal=","
                />
              </Text>
              {donation.goalValue > 0 && (
                <Text
                  color="gray"
                  marginBottom="10"
                  mobileHeight="18"
                  mobileSize="14"
                  size="14"
                  align="right"
                >
                  de {formatMoney(currencies.BRL)(donation.goalValue)}
                </Text>
              )}

              {donation.goalValue > 0 && (
                <div
                  css={css`
                    width: 100%;
                    text-align: right;
                  `}
                >
                  <ProgressBar percentage={percentage} duration={4} />
                  <Text
                    color="gray"
                    size="16"
                    align="right"
                    css={css`
                      margin-top: 5px;
                    `}
                  >
                    <CountUp
                      start={0}
                      end={percentage}
                      decimals={2}
                      decimal=","
                      duration={4}
                    />{' '}
                    %
                  </Text>
                </div>
              )}
            </FlexContainer>
          </FlexContainer>

          <Button
            whileHover={{ scale: 1.03 }}
            whileTap={{ scale: 0.97 }}
            onClick={handleDonate}
          >
            Doar agora!
          </Button>
        </FlexContainer>
      </Spacing>
    </Layout>
  )
}

Live.propTypes = {
  pageContext: PropTypes.objectOf(
    PropTypes.shape({
      institutionId: PropTypes.number.isRequired,
      campaignId: PropTypes.number,
    })
  ).isRequired,
}

export default Live
